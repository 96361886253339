    @import "styles/variables";
    @import "styles/mixins";

.renderer {
    :global {
        /* stylelint-disable selector-class-pattern */
        .prezly-slate-image {
            pointer-events: none;
            margin-top: 0;
        }
        /* stylelint-enable selector-class-pattern */
    }
}
