    @import "styles/variables";
    @import "styles/mixins";

.container {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 40px;
    place-items: center;
    gap: 20px;

    &::before,
    &::after {
        content: "";
        height: 1px;
        width: 100%;
        background-color: #e8e9ed;
    }
}

.items {
    display: flex;
    flex-direction: row;
}

.button {
    font-size: $font-size-xs;
    height: $spacing-6;
    width: $spacing-6;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
        border-color: $color-base-400;
    }

    &:not(:last-child) {
        margin-right: $spacing-1;
    }
}

.icon {
    width: 16px;
    height: 16px;
    margin-top: $spacing-half;
}
