    @import "styles/variables";
    @import "styles/mixins";

.button {
    background-color: #f2f2f2;
    border: 0;
    padding: 0;
    font: inherit;
    color: $color-slate-dark;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
