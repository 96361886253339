    @import "styles/variables";
    @import "styles/mixins";

.container {
    display: flex;
    flex-direction: column;

    @include tablet-up {
        flex-direction: row;
    }
}

.avatar {
    aspect-ratio: 330 / 200;

    @include tablet-up {
        width: 120px;
        height: 120px;
    }

    position: relative;

    .image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.content {
    padding: $spacing-1;
}

.name {
    @include heading-5-custom;

    margin-top: 0;
    margin-bottom: $spacing-1;
    color: $color-slate-darker;
    font-weight: 500;
}

.position {
    margin-bottom: 0;
    margin-top: 0;
}

.contact {
    display: flex;
    align-items: center;
    gap: $spacing-1;
}

.description {
    @include body-4-custom;

    margin-top: 0;
    margin-bottom: $spacing-1;
    color: $color-slate-medium;
}

.links {
    display: flex;
    flex-direction: column;
    gap: 0;
}

.link {
    @include link-primary;
    @include body-1-custom;

    font-size: 13px;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.icon {
    $size: 1rem;

    color: $color-slate-medium;
    width: $size;
    height: $size;
    flex-shrink: 0;
    margin-right: $spacing-half;
}
