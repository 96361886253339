    @import "styles/variables";
    @import "styles/mixins";

$story-container-max-width: 870px;

.container {
    margin: 0 auto;
    background: var(--prezly-background-color);
    padding: 40px;

    @include desktop-up {
        max-width: $story-container-max-width;
    }
}
