    @import "styles/variables";
    @import "styles/mixins";

.languageSwitcher {
    padding-right: $spacing-6;
    display: flex;
    gap: $spacing-2;
    align-items: center;
}

.languageItem {
    @include body-3-custom;

    color: $color-slate-dark;
    font-family: var(--prezly-font-family);
    font-weight: 400;
    text-decoration: none;
}

strong.languageItem {
    color: $color-slate-darker;
    font-weight: 600;
}

.separator {
    color: $color-slate-dark;
}
