    @import "styles/variables";
    @import "styles/mixins";

.wrapper {
    height: 70px;
    padding-left: $spacing-3-5;
    display: flex;
    align-items: center;

    @include desktop-up {
        display: none;
    }
}

.button {
    padding: 0;
    border: 1px solid $color-slate-medium;
    color: $color-slate-medium;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    outline: none;
    font: inherit;
    background: none;
    cursor: pointer;
}

.mobileMenu {
    padding: 0 !important;
    background: var(--prezly-background-color);
    margin: 0 !important;
    height: 100% !important;
    border-radius: 0 !important;
    box-shadow: none !important;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $spacing-2 $spacing-6;
        border-bottom: 1px solid $color-base-200;
    }

    .close {
        padding: 0;
        color: $color-slate-medium;
        border: none;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        font: inherit;
        background: none;
        cursor: pointer;
    }

    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $color-base-200;
        padding: $spacing-2 $spacing-6;
        text-decoration: none;
        color: $color-slate-darker;
    }
}

.categories {
    display: flex;
    flex-direction: column;
}

.mobileWrapper {
    @include mobile-only {
        padding: 0 !important;
        min-height: 0 !important;
        height: 100% !important;
    }
}

.backdrop {
    @include mobile-only {
        display: none;
    }
}
