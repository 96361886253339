    @import "styles/variables";
    @import "styles/mixins";

.footer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $spacing-4;
    background-color: $color-base-white;
    color: $color-slate-dark;

    @include body-2-custom;
}

.links {
    display: flex;
    gap: $spacing-3;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @include desktop-up {
        gap: $spacing-5;
    }
}

.link {
    color: $color-slate-dark;
    text-decoration: none;
}

.copy {
    margin-bottom: $spacing-2;
}

.logoWrapper {
    right: 0;
    position: absolute;
    height: 100%;
    top: -100%;
}

.logo {
    position: relative;
    z-index: 2;
    top: 70%;
    right: 84%;
    transform: translateY(-50%);
}

.shape {
    position: absolute;
    top: 0;
    z-index: 1;
    right: 0;
    pointer-events: none;
    height: 100%;
}
