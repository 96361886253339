    @import "styles/variables";
    @import "styles/mixins";

.filtersContainer {
    margin-bottom: $spacing-7;
}

.storiesContainer {
    display: grid;
    gap: $spacing-7;
    grid-template-columns: 1fr;

    /* stylelint-disable max-nesting-depth */
    @include tablet-up {
        gap: $spacing-7 $spacing-5;
        grid-template-columns: repeat(2, 1fr);
    }

    @include desktop-up {
        gap: $spacing-7;
        grid-template-columns: repeat(3, 1fr);
    }
    /* stylelint-enable max-nesting-depth */
}

.newsLayout {
    display: grid;
    grid-template-columns: 1fr;
    gap: $spacing-5;

    @include desktop-up {
        grid-template-columns: 1fr 1fr;
    }
}

.tileStories {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: $spacing-5;

    > * {
        min-height: 150px;
    }
}
