    @import "styles/variables";
    @import "styles/mixins";

.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.link {
    display: inline-flex;
    align-items: center;
    color: $color-blue-accent;
    text-decoration: none;

    .icon {
        fill: $color-blue-accent;
        margin-left: $spacing-1;
    }
}

.large {
    @include heading-1-custom;

    font-weight: 500;
}

.default {
    @include heading-2-custom;

    font-weight: 400;
}
