    @import "styles/variables";
    @import "styles/mixins";

.search {
    height: 70px;
    padding-left: $spacing-3-5;
    display: flex;
    align-items: center;

    @include desktop-up {
        border-left: 1px solid #bdbdbd;
    }
}

.button {
    padding: 0;
    border: 1px solid $color-slate-medium;
    color: $color-slate-medium;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    outline: none;
    font: inherit;
    background: none;
    cursor: pointer;
}
