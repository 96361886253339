    @import "styles/variables";
    @import "styles/mixins";

.menu {
    display: flex;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.menuItemLink {
    @include body-4-custom;

    font-family: var(--prezly-font-family);
    padding: $spacing-2 $spacing-6;
    color: $color-gray-600;
    font-weight: 500;
    text-decoration: none;
    display: flex;
    gap: $spacing-half;
    align-items: center;
    justify-content: center;
}

.menuItem {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid $color-base-white;
    position: relative;

    .subMenu {
        border-radius: 3px;
        visibility: hidden;
        display: flex;
        position: absolute;
        top: calc(100% - $spacing-half);
        background-color: $color-base-white;
        padding: $spacing-1 $spacing-3;
        list-style: none;
        gap: $spacing-6;
        left: 0;

        .menuItemLink {
            padding: $spacing-1 0 !important;
            white-space: nowrap;
        }

        @include shadow-s;
    }

    &:hover {
        .subMenu {
            visibility: visible;
        }
    }
}
