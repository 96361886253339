    @import "styles/variables";
    @import "styles/mixins";

.container {
    padding: $spacing-9 0;
    color: $color-slate-darker;
    background: $color-blue-light;
}

.columns {
    display: block;

    @include desktop-up {
        display: flex;
    }
}

.heading {
    @include heading-3;

    margin: $spacing-3 0;
    color: $color-slate-darker;
}

.aboutUs {
    flex-grow: 2;
    margin-bottom: $spacing-6;

    @include desktop-up {
        margin-bottom: 0;

        + .contacts {
            margin-left: $spacing-6;
        }
    }
}

.contacts {
    @include tablet-up {
        min-width: 350px;
    }
}

.about {
    margin-bottom: $spacing-5;

    p {
        @include text-label;
    }

    * {
        color: inherit;
    }
}

.contact {
    @include text-label;

    display: flex;
    align-items: flex-start;
    white-space: pre;
    background-color: $color-base-white;
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: $color-gray-200;
    padding: $spacing-3-5;
    margin-bottom: 0;
    margin-top: 0;
    border-radius: 3px;
    color: $color-slate-medium;

    .icon {
        margin-right: $spacing-1;

        // arbitrary value to align icon to text
        margin-top: 0.1875rem;
        flex-shrink: 0;
    }
}

.socialMedia {
    margin-bottom: $spacing-3;
}

.link,
.about a {
    color: $color-slate-darker;
    text-decoration: underline;
}
