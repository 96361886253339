@mixin heading-1-custom {
    font-size: 40px;
    line-height: 44px;
}

@mixin heading-2-custom {
    font-size: 30px;
    line-height: 39px;
}

@mixin heading-3-custom {
    font-size: 18px;
    line-height: 23.4px;
}

@mixin heading-4-custom {
    font-size: 24px;
    line-height: 28px;
}

@mixin heading-5-custom {
    font-size: 18px;
    line-height: 23.4px;
}

@mixin body-1-custom {
    font-size: 17px;
    line-height: 24px;
}

@mixin body-2-custom {
    font-size: 15px;
    line-height: 17.25px;
}

@mixin body-3-custom {
    font-size: 14px;
    line-height: 18.2px;
}

@mixin body-4-custom {
    font-size: 12px;
    line-height: 15.6px;
}
