    @import "styles/variables";
    @import "styles/mixins";

.topMenu {
    display: none;

    @include desktop-up {
        background-color: #f2f2f2;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
