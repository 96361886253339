    @import "styles/variables";
    @import "styles/mixins";

.badge {
    @include border-radius-s;

    display: inline-flex;
    align-items: center;
    font-weight: 700;
    background-color: $color-slate-medium;
    color: $color-base-white;

    @include text-tiny;

    padding: $spacing-half $spacing-1;
    line-height: 140%;
}
