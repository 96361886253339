    @import "styles/variables";
    @import "styles/mixins";

.container {
    position: relative;

    /* stylelint-disable-next-line order/order */
    @include desktop-up {
        &:hover .image {
            transform: scale(1.05);
        }

        &:hover .titleLink {
            background-size: 100% 100%;
        }
    }
}

.horizontal {
    display: flex;
    flex-direction: column;

    @include desktop-up {
        display: flex;
        flex-direction: row;
        gap: $spacing-4;

        .imageWrapper {
            aspect-ratio: unset;
        }

        .image {
            width: 180px;
        }
    }
}

.meta {
    @include text-small;

    display: flex;
    align-items: center;
    gap: $spacing-1;
    overflow: hidden;
    margin-top: $spacing-3;

    &:empty {
        display: none;
    }
}

.date {
    font-size: 13px;
    line-height: 18.2px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: $spacing-half;
    font-family: var(--prezly-font-family);
    color: var(--prezly-text-color-tertiary);
}

.separator {
    display: block;
    align-self: stretch;
    width: 1px;
    background-color: #a1a9af;
}

.imageWrapper {
    display: block;
    flex-shrink: 0;
    overflow: hidden;
    text-decoration: none;
    font-size: 0;
    aspect-ratio: 4 / 3;

    .withStaticImage & {
        aspect-ratio: unset;
    }

    @include desktop-up {
        .image {
            transform: scale(1);
            transition: transform 0.25s ease-in-out;
        }
    }
}

.content {
    padding: $spacing-4;
}

.title {
    @include heading-2;

    margin: 0;
    font-size: 1.625rem;
    line-height: 140%;
    font-weight: $font-weight-semi-bold;

    @include tablet-up {
        @include ensure-max-text-height(4, 140%);
    }
}

.titleLink {
    @include heading-4-custom;

    font-weight: 500;
    text-decoration: none;
    color: var(--prezly-text-color);
    width: calc(100%);
    background-image: linear-gradient(transparent calc(100% - 2px), var(--prezly-text-color) 5px);
    background-repeat: no-repeat;
    background-size: 0 100%;
    transition: background-size 0.7s;
}

.categories {
    display: flex;
    flex-wrap: wrap;
}
