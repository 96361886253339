    @import "styles/variables";
    @import "styles/mixins";

.pressMaterial {
    padding-top: $spacing-8;
    padding-bottom: $spacing-8;
    background-color: $color-blue-lighter;
}

.container {
    @include container;
}

.item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $spacing-1;
    background-color: $color-base-white;
    border: 1px solid $color-slate-medium;
    color: $color-slate-medium;
    padding: $spacing-2;
    border-radius: 3px;
    text-transform: uppercase;
    text-decoration: none;

    @include body-3-custom;

    font-family: var(--prezly-font-family);
    font-weight: 600;
}

.grid {
    display: grid;
    gap: $spacing-5;
    grid-template-columns: 1fr;

    @include tablet-up {
        grid-template-columns: repeat(3, 1fr);
    }

    @include desktop-up {
        grid-template-columns: repeat(3, 1fr);
    }
}
