    @import "styles/variables";
    @import "styles/mixins";

.container {
    display: flex;
    position: relative;
}

.paste {
    color: $color-slate-dark;
    background-color: #f2f2f2;
    font-size: $font-size-xs !important;
    height: $spacing-6;
    width: $spacing-6;
    border: 0 !important;
}

.icon {
    width: 16px;
    height: 16px;
    margin-top: $spacing-1;
}

.messageIcon {
    width: 16px;
    height: 16px;
    margin-right: $spacing-1;
}

.message {
    color: var(--prezly-text-color);
    position: absolute;
    transition: opacity 0.4s ease;
    will-change: opacity;
    inset: 0 0 0 -9rem;
    display: flex;
    align-items: center;
    background-color: var(--prezly-background-color);
}

.transitionStart {
    opacity: 0;
}

.transitionFinish {
    opacity: 1;
}
