    @import "styles/variables";
    @import "styles/mixins";

.features {
    padding-top: $spacing-8;
    padding-bottom: $spacing-8;

    @include container;
}

.feature {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    gap: $spacing-half;

    .value {
        font-weight: 600;
        font-size: 30px;
        color: $color-green-accent;
    }

    .label {
        font-size: 17px;
        color: $color-slate-dark;
    }
}

.grid {
    display: grid;
    gap: $spacing-4;
    grid-template-columns: 1fr;

    @include tablet-up {
        grid-template-columns: repeat(2, 1fr);
    }

    @include desktop-up {
        grid-template-columns: repeat(4, 1fr);
    }
}
