    @import "styles/variables";
    @import "styles/mixins";

.bottomMenu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: $spacing-6;
    padding-right: $spacing-6;
}

.menu {
    display: none;

    @include desktop-up {
        display: flex;
        padding-left: 0;
        padding-right: 0;
        margin-top: 0;
        margin-bottom: 0;
        list-style: none;
    }
}

.menu li:not(:first-child) {
    border-left: 1px solid #bdbdbd;
}

.link {
    @include body-2-custom;

    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--prezly-font-family);
    height: 70px;
    padding-left: $spacing-3-5;
    padding-right: $spacing-3-5;
    font-weight: 500;
    color: $color-slate-darker;
    text-decoration: none;
}
