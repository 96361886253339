@import "../../custom/colors";

$color-base-white: #fff;
$color-base-50: #f9fafb;
$color-base-100: #f6f7f8;
$color-base-200: #e5e7eb;
$color-base-300: #d1d5db;
$color-base-400: #9ca3af;
$color-base-500: #6b7280;
$color-base-600: #4b5563;
$color-base-700: #374151;
$color-base-800: #1f2937;
$color-base-900: #111827;
$color-base-black: #000;
$color-success-shade: #136d34;
$color-success: #22c55e;
$color-success-tint: #51e186;
$color-error-shade: #bc1010;
$color-danger-shade: #e11d48;
$color-error: #ef4444;
$color-error-tint: #f58a8a;
$color-warning-shade: #856605;
$color-warning: #eab308;
$color-warning-tint: #f9cd44;
$color-warning-bg: #fef3c7;

// Gray
$color-gray-200: #e8e9ed;
$color-gray-600: #1f2426;
