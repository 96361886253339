    @import "styles/variables";
    @import "styles/mixins";

.contacts {
    padding-top: $spacing-8;
    padding-bottom: $spacing-8;
    background-color: $color-green-light;
}

.container {
    @include container;
}

.grid {
    display: grid;
    gap: $spacing-2;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    @include not-desktop {
        grid-template-columns: 1fr !important;
    }
}
