    @import "styles/variables";
    @import "styles/mixins";

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.meta {
    @include text-small;

    display: flex;
    align-items: center;
    gap: $spacing-1;
    overflow: hidden;

    &:empty {
        display: none;
    }
}

.categories {
    display: flex;
    flex-wrap: wrap;
}

.date {
    font-size: 13px;
    line-height: 18.2px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: $spacing-half;
    font-family: var(--prezly-font-family);
    color: var(--prezly-text-color-tertiary);
}

.separator {
    display: block;
    align-self: stretch;
    width: 1px;
    background-color: #a1a9af;
}
