    @import "styles/variables";
    @import "styles/mixins";

.container {
    display: flex;
    flex-wrap: wrap;
    gap: $spacing-2;
    margin-bottom: $spacing-6;
    justify-content: center;
    width: 100%;
}

.button {
    padding: $spacing-2 $spacing-4;
    border: 1px solid var(--prezly-text-color);
    border-radius: 3px;
    font-family: var(--prezly-font-family);
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    background: transparent;
    color: var(--prezly-text-color);
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: white;
        color: var(--prezly-text-color);
        border: 1px solid var(--prezly-accent-color);
    }

    &.active {
        background: white;
        color: var(--prezly-text-color);
        border: 1px solid var(--prezly-accent-color);
    }
} 