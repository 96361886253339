$color-green-light: var(--custom-color-green-light);
$color-green-lighter: var(--custom-color-green-lighter);
$color-green-accent: var(--custom-color-green-accent);
$color-slate-light: var(--custom-color-slate-light);
$color-slate-medium: var(--custom-color-slate-medium);
$color-slate-dark: var(--custom-color-slate-dark);
$color-slate-darker: var(--custom-color-slate-darker);
$color-blue-light: var(--custom-color-blue-light);
$color-blue-lighter: var(--custom-color-blue-lighter);
$color-blue-accent: var(--custom-color-blue-accent);
