    @import "styles/variables";
    @import "styles/mixins";

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    display: flex;
    flex-direction: column;
    flex: 1;
}
