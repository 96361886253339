    @import "styles/variables";
    @import "styles/mixins";

.news {
    padding-top: $spacing-8;
    padding-bottom: $spacing-8;
    background-color: $color-green-light;
}

.container {
    @include container;
}
