    @import "styles/variables";
    @import "styles/mixins";

.container {
    display: flex;
}

.link {
    display: block;
    color: $color-slate-darker;

    & + & {
        margin-left: $spacing-3;
    }
}

.icon {
    $size: 30px;

    width: $size;
    height: $size;
}
