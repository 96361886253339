    @import "styles/variables";
    @import "styles/mixins";

.logoWrapper {
    position: relative;
    height: 70px;
    margin-right: auto;
    display: flex;
    align-items: center;

    @include desktop-up {
        display: block;
        margin-right: 0;
        position: absolute;
        left: $spacing-6;
        transform: translateY(100%);
    }

    .logoLink {
        display: flex;
        height: 100%;

        @include desktop-up {
            height: auto;
        }
    }

    .logo {
        position: relative;
        z-index: 2;
    }

    .shape {
        position: absolute;
        left: -$spacing-8;
        pointer-events: none;
        z-index: -1;
        display: none;

        @include desktop-up {
            display: block;
        }

        &::before {
            $shape-svg-offset: 4px;

            height: calc(100% - $shape-svg-offset);
            width: $spacing-6;
            background-color: $color-base-white;
            left: 0;
            transform: translateX(-100%);
            content: "";
            position: absolute;
        }
    }

    .newsroomShape {
        z-index: 1;
        transform: translateX(-40%) translateY(100%);
        position: absolute;
        height: 100%;

        @include desktop-up {
            position: static;
            height: auto;
            z-index: -2;
            transform: translateX(-20%);
        }

        span {
            position: absolute;
            top: 50%;
            right: 50%;
            color: $color-base-white;
            text-decoration: none;
            transform: translateX(50%) translateY(calc(-100% - 4px));

            @include heading-5-custom;

            font-family: var(--prezly-font-family);
            font-weight: 500;

            /* stylelint-disable-next-line max-nesting-depth */
            @include desktop-up {
                transform: translateX(70%) translateY(-50%);
            }
        }
    }
}
