    @import "styles/variables";
    @import "styles/mixins";

.section {
    background-color: $color-blue-light;
    padding-top: 80px;
    padding-bottom: 40px;

    @include desktop-up {
        padding-top: 100px;
        padding-bottom: 60px;
    }
}
